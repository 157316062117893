<template>
	<div class="home-container" style="">
		<!-- <van-nav-bar :title="this.vod_name" class="nav-bar">
			<template #left>
				<van-icon name="arrow-left" color="#fff" @click="back()" />
			</template>
		</van-nav-bar> -->
		<div class="bd-info">
			<div class="info-model">
			<van-pull-refresh :pulling-text = "$t('index.loading')" :loosing-text = "$t('index.loading')" :loading-text = "$t('index.loading')"  border="false" class="list-wrapper" v-model="isLoading" @refresh="onRefresh">
				<!-- <van-grid :column-num="2" :gutter="10">
					<van-grid-item @click="profile(v.id)" v-for="(v, k) in datalist" :key="k">
						<van-image class="game_item_img" :src="v.img_url">
							<template v-slot:loading>
								<van-loading type="circular" />
							</template>
						</van-image>
						<span class="rig-name">{{ v.xuanfei_name }}</span>
					</van-grid-item>
				</van-grid> -->
				<van-list  v-for="(v, k) in datalist" :key="k">
					<div class="model-item" @click="profile(v.id,v.class_id,v.xuanfei_addname)">
						<div class="item-pic">
							<van-image class="movie-list-n-img" :src="v.img_url">
								<template v-slot:loading>
									<van-loading type="circular" />
								</template>
							</van-image>
						</div>
						<div class="item-info">
							<div class="name">
							<span >
							<b>{{ v.xuanfei_name }}</b>
							</span>
							</div>
							<div class="remark">
							<span>{{ v.xuanfei_content }}</span>
							</div>
							<div class="call" >
								<button class="van-button van-button--info van-button--normal van-button--round" style="font-size: 2.867vw;">
									<div class="van-button__content">
										<span class="van-button__text">Tôi muốn hẹn hò với cô ấy
									</span></div>
							</button>
							</div>
						</div>
					</div>
				</van-list>
			</van-pull-refresh>
			</div>
		</div>
		<div style="margin-top: 100px;"></div>
		<van-popup v-model="showNotice" style="background: transparent;width: 90%;text-align: center" @closed="$parent.isNotice=true">
		<dl class="NoticePopup"  >
		<dt style="margin-top: 5px;">{{noticetcname}}</dt>
		<dd v-html="noticetc" style="margin-left: 0px;white-space: pre-wrap;"></dd>
		</dl>
		<a class="close" href="javascript:;" @click="showNotice=false"><van-icon name="clear" color="rgba(255,255,255,.8)" size="2.5rem" /></a>
		</van-popup>
			
	</div>

</template>

<script>
export default {
	data() {
		return {
			notice: this.$t("index.loading"),
			noticetc: this.$t("index.loading"),
			noticetcname: this.$t("index.loading"),
			banners: [{}],
			basicData:[],
			showNotice: false,
			vod_name: '北京',
			isLoading: false,
			datalist: [
				
			]
		};
	},
	methods: {
		getNotice(data){
		this.notice = data.notice;
		this.noticetc = data.noticetc;
		this.noticetcname = data.noticetcname;
		this.showNotice = data.iftc;
		},
		getBasicConfig(){
		this.$http({
		method: 'get',
		url: 'sys_config'
		}).then(res=>{
		this.basicData = res.data;
		this.getNotice(this.basicData);//获取公告
		})
		
		},
		back() {
			this.$router.push({ path: 'Choose' });
		},
		onRefresh() {
			setTimeout(() => {
				this.getxuanfeilist();
				this.$toast(this.$t("reservation.refresh"));
				this.isLoading = false;
			}, 500);
		},
		profile(id,class_id,xuanfei_addname) {
			this.$router.push({ path: '/profile?id=' + id + '&name=' + xuanfei_addname + '&adsid=' + class_id });
		},
		getxuanfeilist() {
			this.$http({
				method: 'get',
				url: 'xuanfeilistnew',
				data: {  }
			}).then(res => {
				this.datalist = res.data;
			});
		}
	},
	created() {
		this.vod_name = this.$route.query.name;
		this.getxuanfeilist();
		this.getBasicConfig();
	}
};
</script>

<style  scoped>


.NoticePopup {
    background: url(../../assets/bg_mine.png) no-repeat #052058;
    background-size: contain;
    height: 21.25rem;
    padding: 0 1.5625rem 1.5625rem 1.5625rem;
    border-radius: .625rem;
    position: relative;
    text-align: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    flex-direction: column;
}

.NoticePopup dt {
    font-size: 1.25rem;
    font-weight: 600;
    color: #fff;
    /* line-height: 3.125rem; */
}

.NoticePopup dd {
    background-color: rgba(0,0,0,.5);
    border-radius: .625rem;
    overflow-y: auto;
    overflow-x: hidden;
    padding: .625rem;
    color: #ccc;
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    flex: auto;
    text-align: justify;
}

.NoticePopup~.close {
    display: inline-block;
    margin-top: .3125rem;
    font-size: 0
}
.right {
	margin-top: 10px;
}
::v-deep .van-grid-item__content--center {
	border-radius: 15px;
	padding: 0;
	height: auto;
}
::v-deep .van-image__img {
	border-radius: 10px;
	padding: 15px;
}
.rig-name {
	width: 100%;
	height: 60px;
	line-height: 60px;
	margin-top: 10px;
	background-color: #f7f7f7;
	border-radius: 0 0 15px 15px;
	font-size: 15px;
	padding-left: 10px;
}
/* ======= */
.van-tabbar {
    height: 14.667vw
}

.van-tabbar-item__icon img {
    height: 6.667vw
}

.van-tabbar-item {
    font-size: 3.467vw
}

.tui {
    width: 5rem;
    height: 5rem!important;
    margin-top: -7.333vw;
    background-color: #fff;
    border-radius: 50%;
    border: 1.333vw solid #fff;
    z-index: 10
}

[class*=van-hairline]:after {
    border: none!important
}

.foorterTxt {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: .725rem
}

body .van-toast {
    font-size: 5.067vw;
    padding: 4vw;
    line-height: 6.667vw;
    width: 70vw
}

body .van-toast .van-toast__icon {
    font-size: 6.667vw
}

*,:after,:before {
    box-sizing: border-box
}

.iframe {
    display: flex;
    justify-content: center;
    align-items: center
}

.linear-bg {
    height: 26.667vw;
    background: linear-gradient(270deg,#ea92cf,#a082f0)
}

.home-container {
    position: absolute!important;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff
}

.linear-gradient {
    width: 100%;
    height: .267vw;
    background: linear-gradient(90deg,rgba(126,86,120,0),#e6c3a1,rgba(126,86,120,0))
}

.van-notice-bar__right-icon, .van-notice-bar__left-icon {
    min-width: 5.333vw
}

.notice-swipe {
    width: calc(100% - 6.667vw);
    height: 11.333vw;
    font-size: 4vw
}

 .van-icon-bullhorn-o:before {
    transform: scale(2.5)
}

.banner {
    width: 100%;
    margin-top: -23%
}

.banner_swiper {
    height: 100%;
    width: 100%
}

.banner_swiper .swiper-slide {
    border-radius: 1.333vw;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 82.667vw;
    height: 40vw;
    text-align: center;
    font-weight: 700;
    font-size: 2.667vw;
    background-color: #fff;
    background-position: 50%;
    background-size: cover;
    color: #fff
}

 .swiper-container-3d .swiper-slide-shadow-left {
    background-image: linear-gradient(270deg,transparent,transparent)
}

 .swiper-container-3d .swiper-slide-shadow-right {
    background-image: linear-gradient(90deg,transparent,transparent)
}

.banner_img {
    border-radius: 1.333vw;
    width: 100%;
    height: 100%
}

.hot-game {
    width: 100%;
    height: 100%
}

.hot-title-div {
    width: calc(100% - 6.667vw);
    margin: 0 auto;
    height: 10.667vw;
    display: flex;
    align-items: center;
    justify-content: space-between
}

.hot-title-div div {
    display: flex;
    align-items: center;
    justify-content: flex-start
}

.hot-title-div>div:nth-child(2) span {
    font-size: 2.667vw
}

.hot-title-div>div:first-child span {
    font-size: 1.333vw;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 3.733vw;
    font-weight: 700;
    color: #000
}

.hot-title-div>div:nth-child(2) span {
    font-size: 3.333vw;
    color: #979799
}

.hot-game .hot-items-div {
    margin-top: -.4vw
}

.hot-game .hot-items-div span {
    margin-top: 1.333vw;
    font-size: 3.733vw;
    color: #000
}

.hot-recommend {
    width: 100%;
    flex: 1;
    background-color: #f2f2f5
}

.movie_swiper .swiper-slide {
    width: 80%
}

.movie_list_0 {
    width: calc(100% - 6.667vw);
    margin: 0 auto
}

.movie_cover {
    border-radius: 1.333vw;
    width: 73.333vw;
    height: 44vw
}

.movie_list_0 .movie-list-item-bottom {
    position: relative;
    width: 73.333vw;
    bottom: 5.733vw
}

.movie_list_0 .movie-list-item-bottom .movie-time-div {
    background-color: rgba(0,0,0,.4)
}

.movie_list_0 .movie-list-item-bottom>div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between
}

.movie_list_0 .movie-list-item-bottom .movie-time-div .van-count-down {
    font-size: 3.733vw;
    color: #fff
}

.movie_list_0 .movie-time-div {
    color: #fff;
    border-radius: 0 0 1.333vw 1.333vw
}

.movie_list_0 .movie_swiper .hot {
    position: absolute;
    top: 0;
    left: 0;
    width: 10.667vw
}

.movie_list_0 span {
    font-size: 4vw
}

.movie_list_1 {
    display: flex;
    width: calc(100% - 6.667vw);
    margin: 0 auto;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap
}

.movie_list_1 .movie-list-item .cover_img {
    border-radius: 1.333vw;
    width: 100%;
    height: 100%
}

.home-scroll {
    padding-bottom: 14.667vw
}

.movie_list_1 .movie-list-item {
    margin-bottom: 1.333vw;
    width: 100%;
    height: 38.667vw;
    position: relative
}

.movie_list_1 .movie-list-item-bottom {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    color: #fff;
    padding: 2vw;
    font-size: 2.133vw;
    background: linear-gradient(180deg,hsla(0,0%,100%,0),rgba(0,0,0,.3),#000);
    box-sizing: border-box
}

.movie_list_1 .movie-list-item:nth-child(odd) {
    margin-right: 1.333vw
}

.movie_list_1 .movie-list-item-bottom .movie-time-div {
    background-color: rgba(0,0,0,.4)
}

.movie_list_1 .movie-list-item-bottom>div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between
}

.movie_list_1 .movie-list-item-bottom .movie-time-div .van-count-down {
    color: #fff
}

.movie_list_1 .movie-time-div {
    color: #fff;
    border-radius: 0 0 1.333vw 1.333vw;
    height: 4.667vw
}

.movie_list_1 .movie_swiper .hot {
    position: absolute;
    top: 0;
    left: 0;
    width: .667vw
}

.movie_list_1 .movie-list-item .movie-time-div span:first-child {
    overflow: hidden;
    white-space: nowrap;
    width: 24vw;
    padding-left: 1.067vw;
    font-size: 3.333vw
}

.movie_list_1 .movie-list-item .movie-time-div span:last-child {
    overflow: hidden;
    white-space: nowrap;
    width: 0;
    padding-right: 14.667vw;
    font-size: 2.933vw
}

.movie_list_0 .movie-time-div span:first-child {
    overflow: hidden;
    white-space: nowrap;
    width: 46.667vw;
    padding-left: 1.333vw;
    font-size: 3.333vw
}

.hot-recommend-more {
    width: 100%;
    padding-bottom: 2.667vw;
    text-align: center;
    color: #979799;
    font-size: 4vw
}

.hot-items-div .game_item_img {
    width: 13.333vw;
    height: 13.333vw
}

 .hot-items-div .game_item_img .van-image__img {
    border-radius: 50%
}

 .van-pull-refresh__track .van-pull-refresh__head * {
    color: #000;
    font-size: 4.667vw
}

.mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center
}

 .van-tabs--line .van-tabs__wrap {
    height: 13.333vw
}

 .van-tabs__wrap--scrollable .van-tab {
    padding: 0 3.067vw
}

 .van-tab .van-tab__text--ellipsis {
    font-size: 3.467vw;
    line-height: 6.667vw;
    height: 6.667vw
}

.box1 {
    height: 53.333vw;
    padding: 1.333vw;
    border-radius: 1.333vw;
    background: linear-gradient(60deg,#765e6e,#c1e2f1);
    overflow: hidden
}

.box2 {
    flex: 1;
    flex-direction: column;
    row-gap: 1.333vw;
    display: flex
}

.box2 .content {
    flex: 1;
    flex-direction: column;
    row-gap: 2.667vw;
    color: #333;
    padding: 2.667vw;
    font-size: 2.933vw;
    border-radius: 2.667vw;
    background: linear-gradient(60deg,#f7f3f5,#f2f4f5)
}

.item2 {
    display: flex;
    margin-bottom: 5.333vw;
    -moz-column-gap: 2.667vw;
    column-gap: 2.667vw
}

.chatList {
    margin-top: 1.333vw;
    height: calc(100% - 4.667vw);
    overflow-y: hidden
}

.chatList .item {
    margin-bottom: 1.333vw;
    font-size: 1.6vw;
    color: #fff;
    line-height: 4.8vw;
    padding: 4vw;
    border-top: 1px solid #fff;
    text-align: left
}

.chatList .img {
    display: flex;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    border-radius: 100%;
    border: 1px solid #e2e2e2
}

.body-box,.home {
    margin: 2.667vw
}

.home div {
    line-height: 6.667vw
}

.service {
    display: inline-block;
    width: 5.867vw;
    height: 5.867vw;
    line-height: 5.867vw;
    text-align: center;
    border-radius: 50%;
    font-weight: 600;
    background: linear-gradient(90deg,#a082f0,#ea92cf);
    color: #fff
}

.page {
    position: absolute!important;
    top: 0;
    left: 0;
    right: 0;
    background-color: #f2f2f5
}

.mine {
    position: relative;
    bottom: 1.333vw;
    background: #f2f2f5
}

.mine .wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 24vw
}

.mine .header,.nav-bar {
    background: linear-gradient(90deg,#a082f0,#ea92cf)
}

.mine .header {
    padding-bottom: 13.333vw
}

 .van-nav-bar__content {
    height: 13.333vw
}

 .van-hairline--bottom:after {
    border-bottom-width: 0
}

.mine .header .van-nav-bar .van-icon {
    font-size: 6vw
}

.mine .header .user-wrapper {
    display: flex;
    align-items: center;
    margin: 0 5.333vw 0 5.333vw
}

.mine .user_img {
    height: 17.333vw;
    width: 17.333vw
}

 .van-loading__spinner {
    height: 6.667vw;
    width: 6.667vw
}

 .van-image__error-icon {
    font-size: 9.333vw
}

.mine .header .user-wrapper .login-content {
    flex: 1;
    margin-left: 4vw
}

.mine .header .user-wrapper .login-content .login-btn {
    display: inline-block;
    font-size: 5.333vw;
    line-height: 0;
    color: #fff;
    display: flex;
    align-items: center
}

.mine .header .user-wrapper .login-content .login-label {
    width: 60%;
    font-size: 3.733vw;
    color: hsla(0,0%,100%,.6)
}

.mine .page-bg {
    height: 66.667vw;
    background: linear-gradient(90deg,#a082f0,#ea92cf);
    z-index: 0;
    background-size: cover
}

.mine .content {
    position: relative;
    padding: 1.333vw 4vw 4vw;
    min-height: 66.667vw;
    background-color: #f2f2f5
}

 .van-pull-refresh__track .van-pull-refresh__head * {
    color: #fff;
    font-size: 4.667vw
}

.mine .wrapper .content .finance {
    position: absolute;
    display: flex;
    align-items: center;
    top: -7.333vw;
    left: 4vw;
    right: 4vw;
    height: 16vw;
    background-color: #fff;
    border-radius: 2vw;
    box-shadow: 0 .2vw 1px 0 #e4e4e7
}

.mine .wrapper .content .finance .line {
    width: .4vw;
    height: 5.333vw;
    background-color: #ccc
}

.mine .wrapper .content .finance .finance-item {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%
}

.mine .wrapper .content .finance .finance-item .text {
    margin-left: 4vw;
    font-size: 4vw;
    color: #000;
    font-weight: 500
}

.mine .wrapper .content .finance .finance-item .icon {
    font-size: 6.667vw
}

.mine .wrapper .content .menu {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    background-color: #fff;
    border-radius: 2vw;
    box-shadow: 0 .2vw 1px 0 #e4e4e7
}

.mine .wrapper .content .menu .menu-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 50%;
    height: 17.333vw
}

.mine .wrapper .content .menu .menu-item .menu-item-label {
    font-size: 4vw;
    color: #868686;
    font-weight: 500
}

.mine .wrapper .content .menu .menu-item .menu-item-icon {
    margin: 3.333vw;
    width: 8vw;
    height: 8vw;
    -o-object-fit: contain;
    object-fit: contain
}

.mine .wrapper .content .wallet {
    margin-top: 10.667vw;
    padding: 0 4vw;
    background-color: #fff;
    border-radius: 2vw;
    box-shadow: 0 .2vw 1px 0 #e4e4e7
}

.mine .wrapper .content .wallet .part-1 {
    display: flex;
    align-items: center;
    height: 13.333vw
}

.mine .wrapper .content .wallet .font-primary-color {
    color: #000
}

.font-gray {
    color: #868686
}

.mine .wrapper .content .wallet .part-2 {
    display: flex;
    align-items: center;
    height: 20vw
}

.mine .wrapper .content .wallet .part-2 .balance {
    flex: 1;
    font-size: 8vw;
    color: #a082f0;
    font-weight: 700
}

.mine .wrapper .content .wallet .van-hairline--bottom:after {
    border-bottom-width: .4vw
}

.mine .wrapper .content .wallet .part-2 .refresh-btn {
    margin-left: 4vw;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 6.667vw;
    height: 6.667vw;
    font-size: 4vw;
    border-radius: 50%;
    color: #fff;
    background-color: #ea92cf
}

.login-vip {
    width: 18.933vw;
    height: 6vw;
    margin-left: 0;
    margin-bottom: -1.6vw
}

.page{
    position: absolute!important;
    top: 0;
    left: 0;
    right: 0;
    background-color: #f2f2f5
}

.nav-bar{
    background: linear-gradient(90deg,#a082f0,#ea92cf);
    height: 13.333vw
}

.van-nav-bar{
    line-height: 6.667vw
}

.van-nav-bar__title {
    max-width: 60%;
    margin: 0 auto;
    color: #fff;
    font-size: 4.667vw
}

.van-nav-bar__content {
    height: 13.333vw
}

.van-sidebar{
    width: 24vw
}

.convention-hall{
    display: flex;
    flex-direction: column;
    bottom: 2.667vw;
    background: #f2f2f5
}

.van-tab {
    font-size: 4vw;
    line-height: 13.333vw;
    font-weight: 700
}

.van-tabs__line {
    background-color: #a082f0
}

.van-tabs--line .van-tabs__wrap {
    height: 13.333vw
}

.van-tabs__wrap--scrollable .van-tab {
    padding: 0 3.067vw
}

.card{
    background-color: #8a637d;
    padding: .625rem;
    width: 95%;
    color: #fff;
    font-size: .8125rem;
    margin: .625rem auto;
    border-radius: .375rem
}

.van-row--flex {
    height: 10.667vw;
    line-height: 10.667vw
}

.van-cell {
    padding: 4vw 2.933vw;
    font-size: 4vw;
    line-height: 4vw
}

.rig-box{
    width: 95%;
    margin: .625rem auto
}

.rig-title{
    color: #0bdab0;
    font-size: 1.125rem
}

.rig-content{
    font-size: 2.667vw
}

.address{
    width: 94%;
    margin: 0 auto
}

.right {
    margin-top: 1.333vw
}

 .van-grid-item__content--center {
    border-radius: 2vw;
    padding: 0;
    height: auto
}

 .van-image__img {
    border-radius: 1.333vw;
    padding: 2vw
}

.rig-name {
    width: 100%;
    height: 8vw;
    line-height: 8vw;
    margin-top: 1.333vw;
    background-color: #f7f7f7;
    border-radius: 0 0 2vw 2vw;
    font-size: 2vw;
    padding-left: 1.333vw
}

.container {
    display: inline-block
}

.box {
    width: 95%;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 6.25rem
}

.name {
    font-size: 1.125rem
}

.title {
    font-size: .625rem
}

.button {
    width: 10rem;
    height: 2.5rem;
    font-size: .9375rem;
    margin-top: .625rem
}

.page {
    position: absolute!important;
    top: 0;
    left: 0;
    right: 0;
    background-color: #f2f2f5
}

.nav-bar {
    background: linear-gradient(90deg,#a082f0,#ea92cf);
    height: 13.333vw
}

.van-nav-bar {
    line-height: 6.667vw
}

 .van-nav-bar__title {
    max-width: 60%;
    margin: 0 auto;
    color: #fff;
    font-size: 4.667vw
}

 .van-nav-bar__content {
    height: 13.333vw
}

.movie-hall {
    display: flex;
    flex-direction: column;
    bottom: 13.333vw;
    background: #f2f2f5
}

 .van-tabs__nav {
    background: linear-gradient(90deg,#a082f0,#ea92cf)
}

 .van-tab {
    color: #fff;
    font-size: 4vw
}

 .van-tabs__line {
    bottom: 2vw;
    width: 7.333vw;
    height: .933vw;
    border-radius: 0;
    background-color: #fff
}

 .van-tabs--line .van-tabs__wrap {
    height: 13.333vw
}

 .van-tabs__wrap--scrollable .van-tab {
    padding: 0 3.067vw
}

 .van-hairline--bottom:after {
    border-bottom-width: 0
}

.video_swiper {
    width: 100%;
    flex: 1
}

.video_swiper .swiper-slide {
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: 100%;
    justify-content: center;
    height: 100%;
    position: relative;
    transition-property: transform
}

.movie-list-tab {
    overflow: auto;
    height: 100%
}

 .van-pull-refresh__track .van-pull-refresh__head * {
    color: #000;
    font-size: 4.667vw
}

.movie-list-tab .hot-recommend-div {
    height: 100%
}

.list-item,.movie-list-tab .hot-recommend-div {
    margin: 1.333vw auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap
}

.list-item {
    width: calc(100% - 6.667vw)
}

.list-item .movie-list-item:nth-child(odd) {
    margin-right: 2.667vw
}

.movie-list-item .cover_img {
    border-radius: 2.667vw;
    width: 44.667vw;
    height: 38.667vw
}

.movie-list-item {
    margin-bottom: -1.333vw
}

.list-item .movie-list-item-bottom {
    position: relative;
    width: 44.667vw;
    bottom: 5.6vw
}

.list-item .movie-list-item-bottom .movie-time-div {
    background-color: rgba(0,0,0,.4)
}

.list-item .movie-list-item-bottom>div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between
}

.list-item .movie-list-item-bottom .movie-time-div .van-count-down {
    color: #fff
}

.list-item .movie-list-item .movie-time-div span:first-child {
    overflow: hidden;
    white-space: nowrap;
    width: 24vw;
    padding-left: 1.067vw;
    font-size: 3.333vw
}

.list-item .movie-time-div {
    color: #fff;
    border-radius: 0 0 2.667vw 2.667vw;
    height: 4.667vw
}

.page{
    position: absolute!important;
    top: 0;
    left: 0;
    right: 0;
    background-color: #f2f2f5
}

.nav-bar{
    background: linear-gradient(90deg,#a082f0,#ea92cf);
    height: 13.333vw
}

.van-nav-bar{
    line-height: 6.667vw
}

.van-nav-bar__title {
    max-width: 60%;
    margin: 0 auto;
    color: #fff;
    font-size: 4.667vw
}

.van-nav-bar__content {
    height: 13.333vw
}

.van-sidebar{
    width: 24vw
}

.van-sidebar-item--select:before {
    left: 1.333vw;
    height: 44%;
    background-color: #a082f0;
    border-radius: .667vw;
    width: 1.333vw
}

.van-sidebar-item--select{
    color: #a082f0;
    font-size: 4.667vw;
    text-align: center
}

.van-sidebar-item__text {
    width: 18.667vw;
    margin-left: -3.333vw
}

.van-sidebar-item{
    font-size: 4vw;
    text-align: center;
    padding: 6.667vw;
    background-color: #fff
}

.van-sidebar-item--select,.van-sidebar-item--select:active {
    background-color: #f2f2f5
}

.convention-item{
    display: flex;
    align-items: center;
    height: calc(100% - 2vw)
}

.convention-hall{
    display: flex;
    flex-direction: column;
    bottom: 2.667vw;
    background: #f2f2f5
}

.convention-item .left{
    height: 100%;
    background-color: #fff;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch
}

.convention-item .right{
    height: 100%;
    flex: 1;
    background-color: #f2f2f5;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch
}

.convention-item .right .list-wrapper{
    padding: 2.667vw 2.667vw;
    min-height: 106.667vw
}

.convention-item .right .list-wrapper .game_item_img{
    width: 26.667vw;
    height: 26.667vw
}

.convention-item .right .list-wrapper span{
    margin-top: 1.333vw;
    font-size: 4vw;
    color: #000
}

.convention-item .right .list-wrapper span:last-child {
    margin-top: 1.333vw;
    font-size: 3.2vw;
    color: #000
}

.van-grid-item{
    padding: 1.333vw
}

.van-grid-item__content--center {
    border-radius: 2vw
}

.van-image__img {
    border-radius: 5.333vw
}

.van-pull-refresh__track .van-pull-refresh__head * {
    color: #000;
    font-size: 4.667vw
}

/* @font-face {
    font-family: me-font;
    src: url(../fonts/Times_New_Roman.e2f6bf4e.ttf)
} */

body {
    font-family: me-font
}

#app {
    font-family: Avenir,Helvetica,Arial,sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50
}

.page {
    position: absolute!important;
    top: 0;
    left: 0;
    right: 0;
    -webkit-overflow-scrolling: touch;
    background-color: #f2f2f5
}

.nav-bar {
    background: linear-gradient(90deg,#a082f0,#ea92cf)
}

.nav-bar,.van-nav-bar__content {
    height: 13.333vw
}

.van-nav-bar__title {
    max-width: 60%;
    margin: 0 auto;
    color: #fff;
    font-size: 4.667vw
}

.van-nav-bar {
    line-height: 6.667vw
}

.van-nav-bar .van-icon {
    font-size: 6vw
}

.van-hairline--bottom:after {
    border-bottom-width: 0
}

.bg-container {
    position: relative;
    bottom: 0;
    z-index: 2
}

.bg-container .bg-img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.bg-container .bg-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(hsla(0,0%,100%,0),#a082f0)
}

.container {
    display: flex;
    flex-direction: column;
    height: 100%;
    z-index: 2
}

.flex-1 {
    flex: 1
}

.font-28 {
    font-size: 3.733vw
}

.nav-right {
    font-size: 4vw;
    color: #fff
}

.van-empty__image {
    width: 40vw;
    height: 40vw
}

.van-empty__description {
    font-size: 4vw
}

.login {
    height: 100%
}

.bg-container .bg-wrapper .login .nav-bar {
    background: 0 0
}

.language {
    position: absolute;
    top: .533vw;
    right: 0;
    height: 10.667vw
}

.language img {
    height: 100%
}

.login .wrapper {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch
}

.logo-container {
    margin: 0 auto;
    width: 45%
}

.logo-container .logo-wrapper {
    position: relative;
    padding-bottom: 62.5%
}

.logo-container .logo-wrapper .logo-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    opacity: .8
}

.login .wrapper .title {
    line-height: 13.333vw;
    text-align: center;
    font-size: 8vw;
    font-weight: 700;
    color: #fff;
    letter-spacing: .667vw
}

.login .wrapper .loginForm {
    padding: 8vw 8vw 0
}

.login .wrapper .loginForm .input {
    padding: 1.333vw 2.667vw;
    margin-top: 5.333vw;
    border-radius: 6.667vw;
    text-align: center;
    line-height: 10.667vw;
    font-size: 4vw;
    color: #4e4e4e
}

 .van-field__right-icon .van-icon, .van-icon {
    font-size: 6.667vw
}

.login .wrapper .loginForm .reset-text {
    margin: 4vw 2vw;
    display: flex;
    align-items: center;
    justify-content: flex-end
}

.login .wrapper .loginForm .reset-text span {
    color: #fff;
    font-size: 3.333vw;
    font-weight: 500;
    line-height: 2vw
}

.login .wrapper .loginForm .register-text {
    margin: 1.333vw 0;
    display: flex;
    align-items: center;
    justify-content: center
}

.login .wrapper .loginForm .register-text span {
    color: #fff;
    font-size: 3.333vw;
    font-weight: 500;
    line-height: 2.667vw
}

.login .wrapper .loginForm .login-btn {
    margin-top: 11.333vw;
    width: 100%;
    height: 13.333vw;
    border-radius: 6.667vw;
    color: #fff;
    background: linear-gradient(90deg,#a082f0,#ea92cf);
    font-size: 4vw;
    font-weight: bolder;
    border: none
}

.register {
    height: 100%
}

.bg-container .bg-wrapper .register .nav-bar {
    background: 0 0
}

.register .wrapper {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch
}

.logo-container {
    margin: 0 auto;
    width: 45%
}

.logo-container .logo-wrapper {
    position: relative;
    padding-bottom: 62.5%
}

.logo-container .logo-wrapper .logo-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    opacity: .8
}

.register .wrapper .title {
    line-height: 13.333vw;
    text-align: center;
    font-size: 6vw;
    font-weight: 700;
    color: #fff;
    letter-spacing: .667vw
}

.register .wrapper .loginForm {
    padding: 8vw
}

.register .wrapper .loginForm .input {
    padding: 1.333vw 2.667vw;
    margin-top: 4.667vw;
    border-radius: 6.667vw;
    text-align: center;
    line-height: 9.333vw;
    font-size: 4vw;
    color: #4e4e4e
}

 .van-field__right-icon .van-icon, .van-icon {
    font-size: 6.667vw
}

.register .wrapper .loginForm .reset-text {
    margin: 4vw 2vw;
    display: flex;
    align-items: center;
    justify-content: flex-end
}

.register .wrapper .loginForm .reset-text span {
    color: #fff;
    font-size: 3.333vw;
    font-weight: 500;
    line-height: 2vw
}

.register .wrapper .loginForm .register-text {
    margin: 1.333vw 0;
    display: flex;
    align-items: center;
    justify-content: center
}

.register .wrapper .loginForm .register-text span {
    color: #fff;
    font-size: 3.333vw;
    font-weight: 500;
    line-height: 2.667vw
}

.register .wrapper .loginForm .login-btn {
    margin-top: 4vw;
    width: 100%;
    height: 13.333vw;
    border-radius: 6.667vw;
    color: #fff;
    background-color: #a082f0;
    font-size: 4vw;
    font-weight: bolder;
    border: none
}

.register .wrapper .loginForm .agreement {
    margin-top: 4vw;
    display: flex;
    align-items: center;
    justify-content: center
}

.register .wrapper .loginForm .agreement .agreement-text {
    margin-left: 1.333vw;
    font-size: 3.333vw;
    color: #fff;
    font-weight: 500;
    line-height: 4vw
}

 .agreement .van-icon {
    font-size: 4vw
}

 .agreement .van-checkbox__icon {
    font-size: 5.067vw
}

 .agreement .van-checkbox__icon--checked .van-icon {
    color: #fff;
    border-color: #7e5678;
    background-color: #7e5678
}

.servicelistItem {
    display: flex;
    flex-direction: column;
    height: 26.667vw;
    padding: 4vw 4vw;
    margin: 4vw 2.667vw;
    border-radius: 2.667vw;
    justify-content: space-between;
    background: #fff
}

.servicelistItem .servicelistItemTop {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 13.333vw
}

.servicelistItem .servicelistItemTop .servicelistItemImage {
    width: 10.667vw;
    height: 10.667vw;
    border-radius: 50%
}

.servicelistItem .servicelistItemTop .servicelistItemText {
    margin-left: 6.667vw;
    font-size: 5.733vw;
    font-weight: 700;
    color: #000;
    flex: 1
}

.servicelistItem .servicelistItemTop .servicelistItemBtn {
    display: flex;
    width: 20vw;
    height: 7.333vw;
    border-radius: 4vw;
    align-items: center;
    justify-content: center;
    background: linear-gradient(270deg,#ea92cf,#a082f0)
}

.servicelistItem .servicelistItemTop .servicelistItemBtn .servicelistItemBtnText {
    color: #fff;
    font-size: 3.333vw
}

.servicelistItem .servicelistItemBottom {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 6.667vw;
    background: #f2f2f5;
    border-radius: 1.333vw;
    color: #979799
}

.servicelistItem .servicelistItemBottom .servicelistItemInfoText {
    font-size: 4vw
}

.container .items {
    background-color: #fff;
    font-size: 4vw;
    color: #000;
    padding: 0 3.333vw
}

.container .items .item {
    padding: 4vw 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom-width: 1.333vw
}

.container .items .van-hairline--bottom:after {
    border-bottom-width: .4vw
}

.container .sign-out {
    margin: 66.667vw 2.667vw 0;
    height: 13.333vw;
    line-height: 13.333vw;
    border-radius: 6.667vw;
    color: #fff;
    font-size: 5.333vw;
    font-weight: bolder;
    border: none;
    background: linear-gradient(270deg,#ea92cf,#a082f0)
}

.container .item .desc {
    font-size: 4vw;
    font-weight: 700;
    color: #979799
}

.container .item .right {
    display: flex;
    flex-direction: row;
    align-items: center
}

.container .main-content {
    padding: 0 2.667vw;
    background-color: #fff
}

.container .main-content .item {
    padding: 4vw 0;
    justify-content: space-between;
    font-size: 4vw
}

.container .main-content .item .right,.container .main-content .item {
    display: flex;
    flex-direction: row;
    align-items: center
}

.container .main-content .item .right img {
    width: 12vw
}

.container .main-content .van-hairline--bottom:after {
    border-bottom-width: .4vw
}

.container .main-content .item .right .desc-cell-number,.container .main-content .item .right .desc {
    font-size: 4vw;
    font-weight: 700;
    color: #979799
}

.avatarbox {
    padding: 2vw;
    color: #000;
    height: 81%;
    background-color: #fff
}

.avatarbox .title {
    padding: 1.067vw 1.333vw 2.667vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 3.733vw
}

.avatarbox .content .van-image {
    width: 14vw;
    height: 14vw;
    margin: 2.5%;
    border-radius: 50%
}

.avatarbox .content {
    padding-bottom: 1.333vw;
    height: 100%;
    overflow-y: auto;
    padding-top: 2.667vw;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center
}

.avatarbox .content .choose {
    width: 12.667vw;
    height: 12.667vw;
    border: .8vw solid #ea92cf
}

.upAvatar {
    width: 96vw;
    background: linear-gradient(90deg,#a082f0,#ea92cf);
    height: 10.667vw;
    line-height: 10.667vw;
    text-align: center;
    margin: auto;
    color: #fff;
    border-radius: 2.667vw
}

.van-cell {
    font-size: 4.667vw;
    line-height: 10.667vw
}

.container p {
    padding: 0 2vw;
    margin-top: 2vw;
    font-size: 4vw;
    color: #dc2037
}

.container .items {
    background-color: #fff;
    font-size: 4vw;
    color: #000;
    padding: 0 3.333vw
}

.container .items .item {
    padding: 1.333vw 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom-width: 1.333vw
}

.container .items .van-hairline--bottom:after {
    border-bottom-width: .4vw
}

.container .sign-out {
    margin: 66.667vw 2.667vw 0;
    height: 13.333vw;
    line-height: 13.333vw;
    border-radius: 6.667vw;
    color: #fff;
    font-size: 5.333vw;
    font-weight: bolder;
    border: none;
    background: linear-gradient(270deg,#ea92cf,#a082f0)
}

.container .item .desc {
    font-size: 4vw;
    font-weight: 700;
    color: #979799
}

.container .item .right {
    display: flex;
    flex-direction: row;
    align-items: center
}

.flex_center {
    display: flex;
    align-items: center
}

.flex_center img {
    width: 8vw;
    height: 8vw;
    margin-right: 2.667vw
}

.info {
    padding: 2.667vw
}

.container .sex {
    background-color: #fff;
    padding: 0 5.333vw
}

.container .sex .item {
    font-size: 4.667vw;
    line-height: 6.667vw;
    padding: 4vw 0
}

 .van-radio__label {
    line-height: 6.667vw;
    margin-left: 4vw
}

 .van-radio__icon {
    font-size: 4vw
}

 .van-radio__icon--checked .van-icon {
    color: #fff;
    border-color: #7e5678;
    background-color: #7e5678
}

.container .van-hairline--bottom:after {
    border-bottom-width: .4vw
}

.container .header {
    background: linear-gradient(270deg,#ea92cf,#a082f0)
}

.recharge {
    padding: 1.333vw 4vw
}

.van-cell {
    line-height: 8.667vw!important
}

.van-button {
    height: 11.6vw!important
}

.van-button__text {
    color: #fff!important
}

 .van-radio__icon {
    font-size: 4vw!important
}

 .van-radio__label {
    margin-left: 3.333vw!important;
    font-size: 4.667vw!important
}

 .van-radio {
    height: 8.667vw!important
}

.form-item {
    margin-top: 5.333vw
}

.form-item-title {
    font-size: 4.8vw;
    font-weight: 700;
    color: #999;
    margin-bottom: 2.667vw
}

.recharge span {
    font-size: 4vw;
    color: #868686;
    font-weight: 500
}

.container .header .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 2.667vw;
    padding-top: 1.333vw;
    margin: auto
}

.container .header .info .title {
    font-size: 3.333vw;
    color: #e5e5e5
}

.container .header .info .value {
    margin: 1.333vw auto;
    color: #fff;
    font-size: 6.667vw;
    border-bottom: 1px solid #fff
}

.container .header .info .tip {
    font-size: 4vw;
    color: #e5e5e5
}

.container .content {
    flex: 1;
    background: #f2f2f5
}

.container .wrapper {
    height: 100%;
    background-color: #fff
}

.container .wrapper .item .title {
    margin: 5.333vw 0;
    line-height: 2.667vw;
    font-size: 4vw;
    font-weight: 500;
    color: #000;
    text-align: center
}

 .van-password-input {
    width: 80%;
    height: 20vw;
    margin: 0 auto
}

 .van-password-input__security li {
    font-size: 4vw;
    line-height: 30;
    background-color: #ebedf0
}

 .van-password-input__security {
    height: 11.2vw
}

 .van-password-input .van-password-input__security .van-password-input__item {
    height: 100%;
    border: 0;
    text-align: center;
    border-radius: 4vw
}

.van-password-input__security i {
    width: 3.333vw;
    height: 3.333vw
}

 .van-key {
    height: 13.333vw;
    font-size: 7.333vw;
    line-height: 2.667vw;
    border-radius: 2.667vw
}

 .van-number-keyboard {
    z-index: 100;
    width: 100%;
    padding-bottom: 4vw;
    background-color: #f2f3f5
}

 .van-key__collapse-icon, .van-key__delete-icon {
    width: 6.667vw;
    height: 6.667vw
}

.container .wrapper .sub-btn {
    margin: 14.667vw 0 0 10%;
    height: 13.333vw;
    width: 80%;
    font-size: 4.667vw;
    border-radius: 6.667vw;
    color: #fff;
    background: linear-gradient(270deg,#ea92cf,#a082f0)
}

.container .wrapper {
    height: 100%;
    background-color: #fff
}

.container .wrapper .item .title {
    margin: 5.333vw 0;
    line-height: 2.667vw;
    font-size: 4vw;
    font-weight: 500;
    color: #000;
    text-align: center
}

 .van-password-input {
    width: 80%;
    height: 20vw;
    margin: 0 auto
}

 .van-password-input__security li {
    font-size: 4vw;
    line-height: 30;
    background-color: #ebedf0
}

 .van-password-input__security {
    height: 11.2vw
}

 .van-password-input .van-password-input__security .van-password-input__item {
    height: 100%;
    border: 0;
    text-align: center;
    border-radius: 4vw
}

.van-password-input__security i {
    width: 3.333vw;
    height: 3.333vw
}

 .van-key {
    height: 13.333vw;
    font-size: 7.333vw;
    line-height: 2.667vw;
    border-radius: 2.667vw
}

 .van-number-keyboard {
    z-index: 100;
    width: 100%;
    padding-bottom: 4vw;
    background-color: #f2f3f5
}

 .van-key__collapse-icon, .van-key__delete-icon {
    width: 6.667vw;
    height: 6.667vw
}

.container .wrapper .sub-btn {
    margin: 14.667vw 0 0 10%;
    height: 13.333vw;
    width: 80%;
    font-size: 4.667vw;
    border-radius: 6.667vw;
    color: #fff;
    background: linear-gradient(270deg,#ea92cf,#a082f0)
}

.van-cell {
    font-size: 4.667vw;
    line-height: 10.667vw;
    display: block
}

 .van-field__label {
    width: 100%
}

.van-cell {
    font-size: 4.667vw;
    line-height: 10.667vw
}

.sub-btn {
    margin: 14.667vw 0 0 10%;
    height: 13.333vw;
    width: 80%;
    font-size: 4.667vw;
    border-radius: 6.667vw;
    color: #fff;
    background: linear-gradient(270deg,#ea92cf,#a082f0)
}

.nav-bar .right {
    padding-left: 1.067vw;
    padding-right: 1.067vw;
    color: #fff;
    font-size: 3.733vw;
    border-radius: 1.333vw;
    border: .267vw solid #fff;
    line-height: 8vw
}

.record {
    padding-left: 2.667vw;
    padding-right: 2.667vw;
    background-color: #fff;
    box-shadow: 0 .267vw .267vw 0 #cacaca;
    z-index: 1
}

.record .period {
    display: flex;
    align-items: center;
    padding: 2.667vw 0
}

.record .period .cover {
    width: 8vw;
    height: 8vw;
    -o-object-fit: cover;
    object-fit: cover
}

.record .period .period-number {
    flex: 1;
    margin-left: 2.667vw;
    margin-right: 1.333vw;
    height: 6.667vw;
    line-height: 6.667vw;
    font-size: 4.667vw;
    font-weight: 700;
    color: #000
}

.van-count-down {
    color: #ff253f;
    font-size: 6vw;
    margin-top: 1.333vw;
    float: right
}

.record .recent {
    display: flex;
    align-items: center;
    height: 14.667vw
}

.kuaisan-ball .left {
    justify-content: flex-start
}

.kuaisan-ball {
    flex: 1;
    display: flex;
    align-items: center
}

.kuaisan-ball .res-img {
    width: 9.333vw;
    height: 9.333vw;
    margin-right: 4vw
}

.kuaisan-ball .res-des {
    font-weight: 700;
    text-align: center;
    color: #000
}

.kuaisan-ball .res-des.middle {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    font-size: 4.667vw;
    margin-right: 2.667vw
}

.van-icon {
    font-size: 5.333vw
}

.down {
    transition: all .5s
}

.up {
    transform: rotate(180deg);
    transition: all .5s
}

.wrapper {
    position: relative;
    flex: 1;
    overflow: hidden
}

.options-bar {
    display: flex;
    align-items: center;
    height: calc(100% - 10.667vw)
}

.options-bar .game {
    flex: 1;
    height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch
}

.options-bar .game .tips {
    display: flex;
    align-items: center;
    height: 13.333vw;
    padding: 0 2.667vw
}

.options-bar .game .tips .odds {
    flex: 1;
    font-size: 4.667vw;
    font-weight: 500;
    color: #ff253f
}

.options-bar .game .tips .play-tip {
    display: flex;
    align-items: center;
    height: 100%
}

 .van-icon-more-o {
    color: #ff253f;
    font-size: 6.667vw
}

.options-bar .game .tips .play-tip .span-text {
    margin-left: 1.333vw;
    font-size: 4.667vw;
    font-weight: bolder;
    color: #ff253f
}

.linear-gradient {
    width: 100%;
    height: .267vw
}

.sumValueTwoSides {
    display: flex;
    padding: 4vw 0;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap
}

.rectangle {
    overflow: hidden
}

.rectangle.large {
    margin: 0 0 4vw 4%;
    width: 20%;
    border-radius: 1.333vw
}

.rectangle .wrapper {
    position: relative;
    padding: 0 1.333vw;
    background: #fff
}

.rectangle .wrapper .content {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%
}

.rectangle.large .wrapper {
    padding-bottom: 100%
}

.rectangle .wrapper .content .name-text.large {
    font-size: 4vw
}

.rectangle .wrapper .content .name-text {
    color: #7d7c7c;
    font-weight: bolder
}

.rectangle .wrapper .content .odd-text.large {
    font-size: 3.333vw;
    margin-top: -4vw
}

.rectangle .wrapper .content .odd-text {
    text-align: center;
    color: #ff253f
}

.bottom-bar {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 13.333vw;
    z-index: 2
}

.bottom-bar .bar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 13.333vw;
    background-color: #fff;
    box-shadow: 0 0 2.667vw 0 #cacaca;
    z-index: 2
}

.bottom-bar .bar .left,.bottom-bar .bar {
    display: flex;
    flex-direction: row;
    align-items: center
}

.bottom-bar .bar .left .item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 13.333vw;
    font-size: 2.667vw
}

.bottom-bar .bar .left .item .text {
    font-size: 2.933vw;
    color: #7d7c7c
}

.jixuanico {
    font-size: 6vw
}

.bottom-bar .bar .left .line {
    width: .267vw;
    height: 6.667vw;
    background: #dadada
}

.bottom-bar .bar .mid {
    margin-left: 1.333vw;
    flex: 1
}

.bottom-bar .bar .mid .text {
    font-size: 4vw;
    font-weight: 500;
    color: #000
}

.bottom-bar .bar .mid .text.num {
    margin: 0 .667vw;
    color: #ff253f
}

.bottom-bar .bar .right {
    padding: 0 4vw;
    margin: 0 4vw;
    color: #fff;
    background: linear-gradient(270deg,#ea92cf,#f905e5);
    font-size: 5.333vw;
    font-weight: 500;
    height: 9.333vw;
    line-height: 9.333vw;
    border-radius: 6.667vw
}

 .van-pull-refresh__track .van-pull-refresh__head * {
    color: #000;
    font-size: 4.667vw
}

 .van-popup {
    position: fixed
}

 .van-overlay {
    position: absolute;
    background-color: rgba(70,67,67,.7)
}

 .van-popup--top {
    top: -1px
}

.wrapper .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1.333vw 0
}

.wrapper .item .left {
    width: 35%;
    font-size: 4vw;
    text-align: center;
    font-weight: 500;
    color: #000
}

.font-weight {
    font-weight: 700!important
}

.wrapper .item .right {
    flex: 1;
    display: flex;
    font-size: 4vw;
    justify-content: center;
    overflow: hidden;
    color: #000
}

.wrapper .item .kuaisan-ball .left {
    justify-content: flex-start
}

.wrapper .item .kuaisan-ball {
    margin-left: 2.667vw;
    flex: 1;
    display: flex;
    align-items: center
}

.wrapper .item .kuaisan-ball .res-img {
    width: 6.667vw;
    height: 6.667vw;
    margin-right: 2.667vw
}

.wrapper .item .kuaisan-ball .res-des {
    font-weight: 700;
    text-align: center;
    color: #000
}

.wrapper .item .kuaisan-ball .res-des.middle {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    font-size: 4.667vw;
    margin-right: 2.667vw
}

.lotteryOne {
    font-size: 3.733vw!important;
    margin-right: 0!important;
    margin: auto
}

.play-type-tip {
    position: unset;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 86.667vw;
    height: 93.333vw;
    max-height: 50%;
    z-index: 10;
    border-radius: 4vw;
    overflow: hidden;
    background-color: #fff;
    color: #000
}

.play-type-tip .title {
    line-height: 12vw;
    background: linear-gradient(90deg,#f905e5,#ea92cf);
    text-align: center;
    color: #fff;
    font-size: 4.667vw;
    font-weight: 500
}

.mask {
    background-color: transparent;
    -webkit-animation-duration: .35s;
    animation-duration: .35s
}

.play-type-tip .wrapper {
    height: calc(100% - 1.333vw);
    background-color: transparent;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch
}

.play-type-tip .wrapper .item {
    padding: 5.333vw 6.667vw;
    display: flex;
    align-items: flex-start
}

.play-type-tip .wrapper .item .van-icon {
    color: #ea92cf;
    font-size: 8vw
}

.play-type-tip .wrapper .item .content .content-title {
    margin-top: 2.933vw;
    font-size: 4.667vw;
    font-weight: 500;
    color: #000;
    line-height: 0
}

.play-type-tip .wrapper .item .content .content-detail {
    margin-top: .667vw;
    font-size: 2.933vw;
    color: #000;
    line-height: 4vw
}

.play-type-tip .wrapper .item .content {
    flex: 1;
    margin-left: 4vw
}

.rectangle.active .wrapper {
    background-color: #ff253f!important
}

.rectangle.active .wrapper .name-text,.rectangle.active .wrapper .odd-text {
    color: #fff!important
}

.bottom-bar .wrapper {
    position: absolute;
    top: 1.333vw;
    left: 0;
    right: 0;
    padding: 2.667vw 2.667vw 1.333vw 2.667vw;
    height: 30.667vw;
    background-color: #fff;
    z-index: 1;
    box-shadow: 0 0 1.333vw 0 #cacaca;
    transition: transform .3s cubic-bezier(.21,1.02,.55,1.01)
}

.bottom-bar .wrapper.active {
    transform: translateY(-100%)
}

.bottom-bar .wrapper .item {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 8.667vw
}

.bottom-bar .wrapper .item .label {
    font-size: 4vw;
    line-height: 4vw;
    color: #000
}

.bottom-bar .wrapper .item .bet-number {
    flex: 1;
    margin: 0 2.133vw;
    overflow: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    color: #ff253f;
    font-size: 4vw;
    font-weight: 500;
    height: 5.333vw;
    line-height: 5.333vw
}

.bottom-bar .wrapper .item .amount-wrapper {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center
}

.van-cell {
    font-size: 4vw;
    line-height: 6.667vw
}

.bottom-bar .wrapper .item .part {
    margin-right: 2.667vw
}

.bottom-bar .wrapper .item .part span {
    font-size: 4vw;
    vertical-align: center;
    color: #000
}

.bottom-bar .wrapper .item .part .number {
    margin: 0 .667vw;
    color: #ff253f;
    font-weight: 500
}

 .van-field__control {
    color: #ff253f
}

.confirm-order-modal {
    position: unset;
    display: flex;
    flex-direction: column;
    margin: auto;
    padding: 0 2.667vw 4vw;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 81.333vw;
    height: 90.667vw;
    max-height: 50%;
    z-index: 10;
    background-color: #fff;
    border-radius: 4vw
}

.confirm-order-modal .head {
    position: relative;
    height: 10.667vw
}

.confirm-order-modal .head .text {
    padding: 0 2.667vw;
    height: 4vw;
    line-height: 1.333vw;
    text-align: center;
    font-size: 4.667vw;
    font-weight: 500;
    color: #f905e5
}

 .confirm-order-modal .van-hairline--bottom:after {
    border-bottom-width: .267vw
}

.van-popup--center {
    border-radius: 4vw
}

.confirm-order-modal .list {
    flex: 1;
    padding: 0 1.333vw;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch
}

.confirm-order-modal .list .lise-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1.333vw 0
}

.confirm-order-modal .list .lise-item .main {
    flex: 1;
    overflow: hidden
}

.confirm-order-modal .list .lise-item .main .bet-name {
    color: #ff253f;
    font-size: 4.667vw;
    font-weight: 500;
    line-height: 0;
    word-wrap: break-word;
    word-break: break-all
}

.confirm-order-modal .list .lise-item .main .detail-text {
    line-height: 0;
    font-size: 3.333vw;
    color: #979799
}

.confirm-order-modal .list .lise-item {
    color: #ff253f
}

.confirm-order-modal .sub-bar {
    display: flex;
    align-items: center;
    margin-top: 4vw;
    justify-content: space-around
}

.confirm-order-modal .sub-bar .item {
    min-width: 40%;
    height: 10.667vw;
    text-align: center;
    box-sizing: border-box;
    border-radius: 6.667vw;
    font-size: 4.667vw;
    font-weight: 500
}

.confirm-order-modal .sub-bar .item.cancel-btn {
    border: .267vw solid #979799;
    color: #979799;
    background-color: #fff
}

.confirm-order-modal .sub-bar .item.sub-btn {
    background: linear-gradient(270deg,#ea92cf,#f905e5);
    color: #fff;
    border: 0
}

.next-number span {
    font-size: 4.667vw;
    font-weight: 700;
    color: #000;
    float: right
}

 .van-loading__text, .van-pull-refresh__track .van-pull-refresh__head * {
    color: #000;
    font-size: 4.667vw
}

.container .content {
    height: calc(100% - 2.667vw);
    overflow: auto
}

.container .content .listItem {
    margin-bottom: 2.667vw;
    padding: 2.667vw 2.667vw 0;
    position: relative;
    color: #000;
    background-color: #fff
}

.container .content .listItem .listTitle {
    font-size: 5.067vw
}

.container .content .listItem .listContent {
    border-bottom: .267vw solid #f2f2f5;
    padding: .667vw 0;
    font-size: 3.333vw
}

.container .content .listItem .listTime {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    height: 9.333vw
}

.container .content .listItem .listTime .listTimeText {
    color: #656566;
    font-size: 4vw
}

.video-js {
    width: 100%;
    font-size: 3.2vw
}

.movie-content {
    flex: 1;
    overflow-y: auto
}

.movie-content .movie-descript {
    width: 100%;
    height: 18.667vw;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 2vw
}

.movie-content .movie-descript p {
    font-size: 4vw;
    font-weight: 700;
    color: #000
}

.movie-content .movie-descript span {
    color: #979799
}

.movie-content .movie-body {
    width: calc(100% - 2.667vw);
    margin: 0 auto
}

 .movie-video .video-js .vjs-big-play-button {
    top: 50%;
    left: 50%;
    margin-top: -6.667vw;
    margin-left: -13.333vw
}

.movie-content .movie-body .movie-title {
    height: 9.333vw;
    display: flex;
    align-items: center;
    justify-content: space-between
}

.movie-content .movie-body .movie-title>div:first-child {
    width: 54.667vw
}

.movie-content .movie-body .movie-title>div:first-child span {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 4vw;
    font-weight: 700;
    color: #000
}

.movie-content .movie-body .movie-title>div:first-child span:before {
    content: "";
    display: block;
    width: 1.067vw;
    height: 4vw;
    background-color: #a082f0;
    border-radius: 3.333vw;
    margin-right: 1.333vw
}

.movie-play-item {
    width: 100%;
    height: 26.667vw;
    border-radius: 1.333vw;
    position: relative;
    display: flex;
    background-color: #fff;
    margin-bottom: 2.667vw
}

.movie-play-item>div {
    height: 100%
}

.movie-play-item>div:first-child {
    width: 26.667vw;
    position: relative
}

.movie-play-item>div:first-child>img {
    width: 100%;
    height: 100%;
    border-radius: 1.333vw 0 0 1.333vw
}

.movie-play-item>div:first-child>div {
    position: absolute;
    width: 100%;
    height: 4vw;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1.333vw;
    background-color: rgba(0,0,0,.4);
    border-radius: 0 0 0 1.333vw
}

.movie-play-item>div:first-child>div .van-count-down {
    color: #fff;
    font-size: 3.333vw
}

.movie-play-item>div:nth-child(2) p {
    width: 66.667vw;
    height: 8vw;
    font-size: 4vw;
    line-height: 4.267vw;
    word-break: break-all;
    overflow: hidden;
    color: #000
}

.movie-play-item>div:nth-child(2) span {
    color: #000
}

.movie-play-item>div:nth-child(2) {
    padding: 2.667vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between
}

abbr,address,article,aside,audio,b,blockquote,body,canvas,caption,cite,code,dd,del,details,dfn,div,dl,dt,em,fieldset,figcaption,figure,footer,form,h1,h2,h3,h4,h5,h6,header,hgroup,html,i,iframe,img,ins,kbd,label,legend,li,mark,menu,nav,object,ol,p,pre,q,samp,section,small,span,strong,sub,summary,sup,table,tbody,td,tfoot,th,thead,time,tr,ul,var,video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    box-sizing: border-box
}

.vjs-big-play-button .vjs-icon-placeholder {
    font-size: 1.63em!important
}

video::-webkit-media-controls-fullscreen-button {
    display: none
}

.noVip .vjs-fullscreen-control {
    display: none
}

.errorMoney {
    width: 80%
}

.errorMoney .header {
    text-align: center;
    color: #fff;
    padding: 4vw;
    background: linear-gradient(90deg,#a082f0,#ea92cf);
    position: relative
}

.errorMoney .header .close {
    position: absolute;
    top: 50%;
    right: 4vw;
    transform: translateY(-50%)
}

.errorMoney .content {
    color: #a082f0;
    padding: 4vw
}

.van-cell {
    font-size: 4.667vw;
    line-height: 10.667vw
}

.container p {
    padding: 0 2vw;
    margin-top: 2vw;
    font-size: 4vw;
    color: #dc2037
}

.manage-card .wrapper {
    height: calc(100% - 1.333vw);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch
}

.wrapper .add-card {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    background-color: #fff;
    height: 33.333vw
}

.wrapper .add-card span {
    margin-left: 1.333vw;
    font-size: 4vw
}

.wrapper .tips {
    margin: 2vw 2vw;
    font-size: 3.333vw;
    color: #979799
}

.wrapper .bank .info {
    margin-left: 2.667vw;
    flex: 1;
    color: #000
}

.wrapper .bank .info .row-content {
    margin: 4vw 0;
    line-height: 2.667vw;
    font-size: 4vw
}

.van-cell {
    font-size: 4.267vw;
    line-height: 10.667vw
}

.van-hairline--bottom:after {
    border-bottom-width: .4vw
}

.bankbox {
    padding: 2vw;
    color: #000;
    background-color: #fff
}

.bankbox .title {
    padding: 1.067vw 1.333vw 2.667vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 3.733vw
}

.main-box {
    background: #fff
}

.main-box .label {
    padding: 2.667vw;
    font-size: 4.667vw;
    color: #797878
}

 .van-picker__toolbar {
    height: 10.667vw
}

.van-picker__confirm, .van-picker__cancel {
    padding: 0 2.667vw;
    font-size: 2.667vw
}

 .van-picker-column {
    font-size: 5.333vw
}

.main-box p {
    padding: 0 2.667vw;
    font-size: 4vw;
    color: #ee0a24
}

.bindCard {
    margin: 2.667vw 4vw 0;
    height: 10.667vw;
    line-height: 1.22667rem;
    border-radius: 6.667vw;
    color: #fff;
    font-size: 4vw;
    font-weight: bolder;
    border: none;
    background: linear-gradient(90deg,#ea92cf,#a082f0)
}

 .van-picker__cancel, .van-picker__confirm {
    font-size: 4.8vw
}

.van-cell {
    font-size: 4.667vw;
    line-height: 10.667vw
}

.container p {
    padding: 0 2vw;
    margin-top: 2vw;
    font-size: 4vw;
    color: #dc2037
}

.container .main {
    display: flex;
    flex-direction: column;
    background-color: #f2f2f5;
    height: calc(100% - 6.667vw);
    position: relative
}

.container .main .withdrawMoney {
    display: flex;
    flex-direction: column;
    color: #000;
    padding: 0 2.667vw;
    white-space: nowrap;
    font-size: 4.667vw;
    background-color: #fff
}

.container .main .withdrawMoney .money {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #f2f2f5
}

.container .main .withdrawMoney .money .moneyNumber {
    font-size: 6.667vw;
    display: flex;
    flex-direction: row
}

.container .main .withdrawMoney .money .all {
    color: #d10404
}

.container .main .withdrawMoney .money .moneyNumber .van-cell {
    font-size: 6.667vw;
    padding: 0!important
}

.container .main .withdrawMoney .information {
    padding-bottom: 4vw
}

.container .main .withdrawMoney .information .description {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 1.333vw 0
}

.container .main .withdrawMoney span {
    padding: 1.333vw 0
}

.container .main .withdrawMoney .information .balance .number {
    color: #d10404
}

.withdraw_btn {
    margin: 2.667vw 4vw 0;
    height: 10.667vw;
    line-height: 1.22667rem;
    border-radius: 6.667vw;
    color: #fff;
    font-size: 4vw;
    font-weight: bolder;
    border: none;
    background: linear-gradient(90deg,#ea92cf,#a082f0)
}

.container .header {
    background: linear-gradient(270deg,#ea92cf,#a082f0)
}

.container .header .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 2.667vw;
    padding-top: 1.333vw;
    margin: auto
}

.container .header .info .title {
    font-size: 3.333vw;
    color: #e5e5e5
}

.container .header .info .value {
    margin: 1.333vw auto;
    color: #fff;
    font-size: 6.667vw;
    border-bottom: 1px solid #fff
}

.container .header .info .tip {
    font-size: 4vw;
    color: #e5e5e5
}

.container .content {
    flex: 1;
    background: #f2f2f5
}

.container .content .datalist {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start
}

.container .content .datalist .datalistitem {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 26.667vw;
    width: 50%
}

.container .content .datalist .datalistitem .datalistitemValue {
    color: #ff253f;
    font-size: 4.667vw;
    margin-bottom: 1.333vw;
    margin-top: 1.333vw
}

.container .content .datalist .datalistitem .datalistitemKey {
    color: #979799;
    font-size: 3.333vw;
    margin-bottom: 1.333vw;
    margin-top: 1.333vw
}

 .van-loading__text, .van-pull-refresh__track .van-pull-refresh__head * {
    color: #000;
    font-size: 4.667vw
}

.container .main {
    position: relative;
    overflow: auto;
    background-color: #f2f2f5;
    height: 100%;
    padding: 0 1.333vw
}

.item_list {
    padding: 2vw 2vw;
    margin: 4vw 1.333vw;
    background: #fff;
    border-radius: 1.333vw;
    line-height: 8vw
}

.item_list .topInfo span {
    flex: 1;
    font-size: 4.667vw;
    font-weight: 700;
    color: #ff253f
}

.item_list .time span {
    flex: 1;
    font-size: 3.333vw;
    font-weight: 500;
    color: #000
}

.item_list .topInfoSp span {
    font-weight: 700;
    color: #ff253f!important
}

.item_list .topInfo span:last-child {
    float: right
}

.item_list .desc span {
    font-size: 3.333vw;
    font-weight: 700;
    color: #9b9b9b
}

.item_list .cover {
    width: 8vw;
    height: 8vw;
    -o-object-fit: cover;
    object-fit: cover
}

.item_list .period-number {
    margin-left: 6.667vw;
    margin-right: 1.333vw;
    height: 6.667vw;
    line-height: 8vw;
    font-size: 4.667vw;
    font-weight: 700;
    color: #000
}

.item_list .lottery_info {
    display: flex
}

.recent {
    display: flex;
    align-items: center;
    height: 13.333vw
}

.kuaisan-ball .left {
    justify-content: flex-start
}

.kuaisan-ball {
    flex: 1;
    display: flex;
    align-items: center
}

.kuaisan-ball .res-img {
    width: 9.333vw;
    height: 9.333vw;
    margin-right: 4vw
}

.kuaisan-ball .res-des {
    font-weight: 700;
    text-align: center;
    color: #000
}

.kuaisan-ball .res-des.middle {
    margin-right: 2vw;
    font-size: 4.667vw
}

 .van-loading__text, .van-pull-refresh__track .van-pull-refresh__head * {
    color: #000;
    font-size: 4.667vw
}

.container .main {
    position: relative;
    overflow: auto;
    background-color: #f2f2f5;
    height: 100%;
    padding: 0 1.333vw
}

.item_list {
    padding: 2vw 2vw;
    margin: 4vw 1.333vw;
    background: #fff;
    border-radius: 1.333vw;
    line-height: 8vw
}

.item_list .topInfo span {
    flex: 1;
    font-size: 4.667vw;
    font-weight: 700;
    color: #ff253f
}

.item_list .time span {
    flex: 1;
    font-size: 3.333vw;
    font-weight: 500;
    color: #000
}

.item_list .topInfo span:last-child {
    float: right
}

.item_list .desc span {
    font-size: 3.333vw;
    font-weight: 700;
    color: #9b9b9b
}

 .van-loading__text, .van-pull-refresh__track .van-pull-refresh__head * {
    color: #000;
    font-size: 4.667vw
}

.container .main {
    position: relative;
    overflow: auto;
    background-color: #f2f2f5;
    height: 100%;
    padding: 0 1.333vw
}

.item_list {
    padding: 2vw 2vw;
    margin: 4vw 1.333vw;
    background: #fff;
    border-radius: 1.333vw;
    line-height: 8vw
}

.item_list .topInfo span {
    flex: 1;
    font-size: 4.667vw;
    font-weight: 700;
    color: #ff253f
}

.item_list .time span {
    flex: 1;
    font-size: 3.333vw;
    font-weight: 500;
    color: #000
}

.item_list .topInfo span:last-child {
    float: right
}

.item_list .desc span {
    font-size: 3.333vw;
    font-weight: 700;
    color: #9b9b9b
}

 .van-loading__text, .van-pull-refresh__track .van-pull-refresh__head * {
    color: #000;
    font-size: 4.667vw
}

.container .content {
    height: calc(100% - 2.667vw);
    overflow: auto
}

.container .content .listItem {
    margin-bottom: 2.667vw;
    padding: 2.667vw;
    position: relative;
    color: #000;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center
}

.container .content .listItem .listTitle {
    font-size: 5.067vw;
    margin-bottom: 2.667vw
}

.container .content .listItem .listContent {
    border-bottom: .267vw solid #f2f2f5;
    padding: .667vw 0;
    font-size: 3.333vw
}

.container .content .listItem .listMoney {
    color: #dc2037
}

.container .content .listItem .listTime .listTimeText {
    color: #656566;
    font-size: 4vw
}

.changePssword-cell-name {
    font-size: 30rpx;
    font-weight: 700;
    margin-bottom: 20rpx;
    text-align: center
}

.changePssword-cell {
    margin: 80rpx 0
}

.payBtn {
    text-align: center;
    width: 85%;
    margin: 0 4vw;
    margin: 8vw auto;
    padding: 2.667vw;
    color: #fff!important;
    background: linear-gradient(90deg,#a082f0,#ea92cf)
}

.inp-bg {
    text-align: center!important
}

.frame {
    width: 100vw;
    height: 90vh
}

.linear-bg{
    height: 26.667vw;
    background: linear-gradient(270deg,#ea92cf,#a082f0)
}

.home-container{
    position: absolute!important;
    top: 0;
    left: 0;
    right: 0;
    background-color: #5731bc
}

.movie_list_n{
    display: flex;
    flex-wrap: wrap;
    margin: 1.333vw;
    padding-bottom: 16vw
}

.movie-list-n-item{
    width: calc(50% - 1.467vw);
    background-color: #fff;
    border: 1px solid #fff;
    color: #000;
    margin: 2.667vw .667vw;
    border-radius: 2.667vw;
    padding: 1.333vw
}

.movie-n-time-div{
    margin-top: 2.667vw
}

.movie-list-n-title{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 2.667vw;
    font-weight: 700
}

.movie-list-n-img{
    width: 44vw;
    height: 44vw;
    position: relative
}

.movie-list-n-lab{
    display: inline;
    position: absolute;
    top: 2vw;
    left: 2vw;
    padding: .2em .6em .3em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
    background-color: #a082f0;
    width: 42vw;
    overflow: hidden
}

.movie-list-ico-loves{
    display: inline-block;
    width: 5.333vw
}

.movie-list-notice{
    display: flex;
    align-items: center;
    margin-top: 1.333vw
}

.movie-list-ico-notice{
    width: 4vw;
    display: inline-block;
    margin-right: 1.333vw
}

.movie-list-addr{
    display: flex;
    align-items: center;
    margin-top: 1.333vw
}

.movie-list-ico-addr{
    width: 4vw;
    display: inline-block;
    margin-right: 1.333vw
}

.movie-list-money{
    display: flex;
    align-items: center;
    margin-top: 1.333vw
}

.movie-list-ico-money{
    width: 4vw;
    display: inline-block;
    margin-right: 1.333vw
}

.van-tabs--line .van-tabs__wrap {
    height: 13.333vw
}

.van-tabs__wrap--scrollable .van-tab {
    padding: 0 3.067vw
}

.bd-info .info-model .model-item{
    display: flex;
    position: relative;
    background-color: #8f65fe;
    border-radius: .5rem;
    margin: .625rem;
    padding: .125rem
}

.bd-info .info-model .model-item .item-pic{
    position: relative;
    width: 7.5rem;
    overflow: hidden;
    border-radius: 1.333vw;
    margin-right: .625rem
}

.bd-info .info-model .model-item .item-info{
    flex: 1;
    font-size: .75rem
}

.bd-info .info-model .model-item .item-pic img{
    width: 7.5rem;
    height: 11.25rem;
    border: 0;
    vertical-align: middle
}

.bd-info .info-model .model-item .item-info .name{
    margin-bottom: .625rem;
    margin-top: .3125rem
}

.bd-info .info-model .model-item .item-info .tag{
    margin-bottom: .3125rem
}

.bd-info .info-model .model-item .item-info .tag span{
    font-size: .75rem;
    color: #fff;
    display: inline-block;
    margin-right: .3125rem;
    margin-bottom: .3125rem;
    background-color: #ea92cf;
    padding: .1875rem .3125rem;
    border-radius: .3125rem
}

.bd-info .info-model .model-item .item-info .info{
    margin-bottom: .3125rem
}

.bd-info .info-model .model-item .item-info .remark{
    line-height: 1.6;
    margin-bottom: .75rem;
    display: -webkit-box;
    overflow: hidden;
    white-space: normal!important;
    text-overflow: ellipsis;
    word-wrap: break-word;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical
}

.bd-info .info-model .model-item .item-info .call .van-button{
    width: 100%;
    height: 2.125rem!important;
    border-radius: 6.1875rem!important
}

.bd-info .info-model .model-item .item-info .remark span{
    color: #ddd2fb;
    font-size: .875rem
}

.bd-info .info-model .model-item .item-info .name span{
    display: inline-block;
    color: #fff;
    font-size: .875rem
}

.bd-info .info-model .model-item .item-info .info span{
    color: #ddd2fb;
    font-size: .875rem
}

.van-button--info,.van-button--primary{
    border: none!important;
    background: linear-gradient(90deg,#a082f0,#ea92cf)!important;
    border-radius: .667vw!important
}

.nHome-detail {
    background: #f2f2f5;
    height: 100vh;
    overflow: auto;
    color: #000
}



.movie-list-n-lab {
    display: inline;
    position: absolute;
    top: 2vw;
    left: 2vw;
    padding: .2em .6em .3em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
    background-color: #a082f0;
    width: 42vw;
    overflow: hidden
}

.movie-list-ico-loves {
    display: inline-block;
    width: 5.333vw
}

.movie-list-notice {
    display: flex;
    align-items: center;
    margin-top: 1.333vw
}

.movie-list-ico-notice {
    width: 4vw;
    display: inline-block;
    margin-right: 1.333vw
}

.movie-list-addr {
    display: flex;
    align-items: center;
    margin-top: 1.333vw
}

.movie-list-ico-addr {
    width: 4vw;
    display: inline-block;
    margin-right: 1.333vw
}

.movie-list-money {
    display: flex;
    align-items: center;
    margin-top: 1.333vw
}

.movie-list-ico-money {
    width: 4vw;
    display: inline-block;
    margin-right: 1.333vw
}

.detail-title {
    font-size: 5.333vw;
    margin: 2.667vw 0
}

.detail-tabs {
    color: #e74c3c;
    font-size: 4vw;
    flex: 1;
    padding: 5.333vw 2.667vw;
    align-items: center;
    display: flex;
    justify-content: center
}

.detail-tabs.active {
    color: #a082f0
}

.detail-tabs-ico {
    width: 4vw;
    margin-right: 1.333vw
}

.movie-list-n-item-bottom {
    padding: 4vw
}

.tabs1 {
    display: flex;
    flex-wrap: wrap
}

.tabs1-img {
    width: 50%;
    padding: 1.333vw
}

.table .table-list {
    padding: 2.667vw 4vw;
    display: flex;
    background: #eee
}

.table .table-list .name {
    width: 30%;
    display: flex;
    align-items: center
}

.table .table-list .content {
    width: 70%
}

.table .table-list:nth-child(2n) {
    background: #999
}

.table-title {
    padding: 4vw;
    font-size: 4.8vw;
    background: #ccc;
    border-bottom: 1px solid #eee
}

.comment {
    padding: 4vw
}

.commentList-content {
    display: flex;
    margin-bottom: 4vw;
    font-size: 3.733vw
}

.commentList-content-r {
    margin-left: 2.667vw;
    display: flex
}

.commentList-content-avatar {
    width: 8vw;
    height: 8vw;
    margin-right: 2.667vw
}

.commentList-content-name {
    margin-bottom: 1.333vw;
    color: #a082f0;
    font-weight: 700
}

.commentList-content-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1.333vw
}

.commentList-content-left {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #a082f0
}

.commentList-content-hf {
    margin-right: 1.333vw;
    width: 4.533vw
}

.reply {
    display: flex;
    align-items: center;
    margin-left: 2.667vw;
    margin-top: 1.333vw
}

.reply .inp {
    background: #999;
    margin-right: 1.333vw;
    border-radius: 4vw;
    padding: 1.333vw 2.667vw;
    width: 100%;
    border: none
}

.reply .btn {
    display: inline-block;
    padding: 1.333vw 2vw;
    margin-bottom: 0;
    font-size: 2.933vw;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-radius: .533vw;
    color: #d6d6be;
    background-color: #7c1022;
    border-color: #7c1022
}

 .van-loading__text, .van-pull-refresh__track .van-pull-refresh__head * {
    color: #000;
    font-size: 4.667vw
}

.container .content {
    height: calc(100% - 2.667vw);
    overflow: auto
}

.container .content .listItem {
    margin-bottom: 2.667vw;
    padding: 2.667vw;
    position: relative;
    color: #000;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center
}

.container .content .listItem .listTitle {
    font-size: 4vw;
    margin-bottom: 2.667vw;
    max-width: 53.333vw;
    font-weight: 700;
    word-break: break-all
}

.container .content .listItem .listContent {
    border-bottom: .267vw solid #f2f2f5;
    padding: .667vw 0;
    font-size: 3.333vw
}

.container .content .listItem .listMoney {
    color: #dc2037
}

.container .content .listItem .listTime .listTimeText {
    color: #656566;
    font-size: 4vw
}

.container .header {
    background: linear-gradient(270deg,#ea92cf,#a082f0)
}

.container .header .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 2.667vw;
    padding-top: 1.333vw;
    margin: auto
}

.container .header .info .title {
    font-size: 3.333vw;
    color: #e5e5e5
}

.container .header .info .value {
    margin: 1.333vw auto;
    color: #fff;
    font-size: 6.667vw;
    border-bottom: 1px solid #fff
}

.container .header .info .tip {
    font-size: 4vw;
    color: #e5e5e5
}


</style>
